@import "variables";
@import "src/theme.scss";

body {
    font-family: $font-family-1;
    color: $grey-3;
}

html,
body {
    //overflow: hidden;
    width: 100%;
}

.blue-color {
    background-color: $blue-2;
    color: #fff;
}

.white-color {
    background-color: #fff;
    color: $blue-2;
}
::-webkit-scrollbar,
*::-webkit-scrollbar,
html::-webkit-scrollbar,
body::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}
.fa
    :not(
        p,
        span,
        a,
        input,
        select,
        option,
        li,
        ul,
        ol,
        div,
        article,
        section,
        aside,
        header,
        footer,
        i
    ) {
    font-family: $font-family !important;
}
.iti.separate-dial-code {
    width: 100%;
    .iti__selected-flag {
        padding: 0;
        background: transparent !important;
        .selected-dial-code {
            margin: 0;
            font-size: 1.3rem;
        }
    }
    input#phone {
        opacity: 1 !important;
        border: none;
        box-shadow: none;
        // background: transparent;
        border-bottom: 0.1rem solid $grey-0;
        border-radius: 10px;
        font-weight: 500;
        color: $blue-3 !important;
        &::placeholder {
            opacity: 0.4 !important;
        }
        &:focus {
            box-shadow: none !important;
        }
    }
}
.iti__flag-container {
    padding: 6px !important;
    .dropdown-menu.country-dropdown {
        width: 36.7rem;
        top: -4.5rem !important;
        left: 2rem !important;
        right: 2rem !important;
        bottom: 3rem !important;
        height: fit-content;
        z-index: 999999;
        border-radius: 5px;
        .search-container {
            input#country-search-box {
                padding: 1rem 0 1rem 0.6rem;
                background: inherit;
                line-height: normal;
                &:focus-visible {
                    box-shadow: none;
                    outline: none;
                }
            }
        }
        ul.iti__country-list {
            max-height: calc(100vh - 8.5rem);
            .iti__country {
                padding: 1rem;
                .iti__country-name,
                .iti__dial-code {
                    font-size: 1.4rem;
                    font-weight: 300;
                }
            }
        }
    }
}
.account-verification-area {
    .iti.separate-dial-code {
        .iti__selected-flag {
            //   padding-left: 10px;
            //   position: absolute;
            //   z-index: 99999;
            .selected-dial-code {
                font-size: 1.4rem;
            }
        }
        input#phone {
            border: 1px solid $blue-2;
            border-radius: 10px;
            color: $blue-3;
            font-size: 1.3rem !important;
            font-weight: 400;
            background: $blue-0;
            height: 43px;
            padding-left: 80px !important;
            letter-spacing: 0.5px;
        }
    }
}
.fp-parent-div {
    .form-group {
        .iti.separate-dial-code {
            .iti__selected-flag {
                // padding-left: 10px;
                // position: absolute;
            }
        }
        input#phone {
            // background: transparent;
            height: 35px;
            width: 100%;
            padding-top: 2px;
            padding-right: 2px;
            padding-bottom: 1px;
            padding-left: 100px !important;
            font-size: 1.3rem;
            &::placeholder {
                font-size: 1.3rem;
            }
            &:focus-visible {
                outline: none;
            }
        }
        .full-opacity input#phone {
            opacity: 1;
        }
        .low-opacity {
            opacity: 0.4 !important;
        }
    }
}

html {
    font-size: 62.5%;
}

@media (max-width: 400px) {
    html {
        font-size: 60%;
    }
}
@media (max-width: 350px) {
    html {
        font-size: 58%;
    }
}
