@import "/src/theme.scss";

.c-mo-mobile-only {
    display: initial;
}

@media (min-width: $mobile-width) {
    .c-mo-mobile-only {
        display: none;
    }
}
