@import "/src/theme.scss";

.c-mb-main-button {
    border: none;
    background: $blue-2;
    color: $white;
    padding: $base-spacing * 3 $base-spacing * 6;
    font-size: $font-size-m;
    font-weight: $font-weight-semibold;
    border-radius: $base-spacing * 2.5 !important;

    &:disabled {
        background: $grey-1;
        color: $grey-5;
    }

    &.light {
        background: $blue-2;
    }

    &.dark {
        background: $blue-3;
    }

    &.small-icon {
        ion-icon {
            font-size: 18px;
            margin: calc($base-spacing * -0.5);
            padding-right: $base-spacing;
        }
    }

    &.medium-icon {
        ion-icon {
            font-size: 24px;
            margin: calc($base-spacing * -1.5);
            padding-right: $base-spacing * 2;
        }
    }

    &.large-icon {
        ion-icon {
            font-size: 32px;
            margin: calc($base-spacing * -2.5);
            padding-right: $base-spacing * 3;
        }
    }
}
