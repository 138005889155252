@import "/src/theme.scss";

.c-ca-rounded-corners {
    border-radius: $base-spacing * 2;
}

.c-ca-shadow {
    box-shadow: $base-spacing $base-spacing $base-spacing * 2 0
        rgba(0, 0, 0, 0.2);
}

.c-ca-internal-padding {
    padding: $base-spacing * 4;
}

.c-ca-pointer {
    cursor: pointer;
}

@media (max-width: $mobile-width) {
    .c-ca-rounded-corners {
        border-radius: $base-spacing;
    }

    .c-ca-shadow {
        box-shadow: calc($base-spacing/2) calc($base-spacing/2) $base-spacing 0
            rgba(0, 0, 0, 0.2);
    }

    .c-ca-internal-padding {
        padding: $base-spacing * 2;
    }
}
