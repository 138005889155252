// This is the new file containing theming related variables

// Breakpoints -- keep these values in sync with those in /src/utils/theme.ts
$mobile-width: 768px;
$desktop-width: 1024px;
$large-desktop-width: 1220px;

// Spacing should be a multiple of this
$base-spacing: 4px;

// These are fixed components at the top of the page
$header-height: 44px;
$header-desktop-height: 80px;
$drawer-height: 70px;
$drawer-height-compressed: 44px;
$footer-mobile-height: 100px;
$search-height: 80px;

$font-size-xxxs: 8px;
$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-m: 16px;
$font-size-l: 18px;
$font-size-xl: 20px;
$font-size-xxl: 24px;
$font-size-xxxl: 28px;
$font-size-xxxxl: 32px;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$blue-0: #dbf0fa;
$blue-1: #6fc2eb;
$blue-2: #0e67de;
$blue-3: #092e41;
$green-0: #e3f1d4;
$green-1: #cee6b3;
$green-2: #90c652;
$green-3: #547a29;
$green-4: #47c447;
$red-0: #fbcbcb;
$red-1: #f68e8e;
$red-2: #ee2f2f;
$red-3: #840b0b;
$red-4: #f43838;
$yellow-0: #ffeec0;
$yellow-1: #fee49a;
$yellow-2: #feba02;
$yellow-3: #cb9501;
$grey-0: #dee2e6;
$grey-1: #ced4da;
$grey-2: #adb5bd;
$grey-3: #6c757d;
$grey-4: #343a40;
$grey-5: #968c8c;
$gray-6: #d9d9d9;
$gray-7: #e5e5e5;
$gray-96: #f3f3f3;
$gray-97: #f7f7f7;
$grey-98: #f8f9fa;
$grey-99: #ababab;
$white: #ffffff;
$background: #d2e6f9;

.modal {
    --bs-modal-width: 551px;
}

.c-o-modal-offcanvas .modal-body {
    padding: 0 1rem;
    @media (max-width: $mobile-width) {
        position: relative;
        display: contents;
    }
}

// this is the base style for the input. When the refactoring is done we need to remove this class from all inputs
input.refactoringTemp,
textarea.refactoringTemp {
    background: $blue-0;
    color: $blue-3;
    border-radius: calc($base-spacing * 2);
    font-weight: 400;
    line-height: 1.28571;
    border: none;

    &:focus {
        border: calc($base-spacing / 2) solid $blue-3;
    }

    &::placeholder {
        color: $blue-1;
    }

    &[error] {
        border: calc($base-spacing / 2) solid $red-2;
    }
}

div.pane.sidebar-open {
    overflow: hidden;
}

.ripleEffect {
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}

.ripleEffect:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $blue-0 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
}

.ripleEffect:active:after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
}