@import "/src/theme.scss";

.c-do-desktop-only {
    display: initial;
}

@media (max-width: $mobile-width - 1) {
    .c-do-desktop-only {
        display: none;
    }
}
