@import "/src/theme.scss";

.som-control {
    display: none;
}

@media (min-width: $mobile-width) {
    .som-control.sm {
        display: initial;
    }
}

@media (min-width: $desktop-width) {
    .som-control.sm,
    .som-control.md {
        display: initial;
    }
}

@media (min-width: $large-desktop-width) {
    .som-control.sm,
    .som-control.md,
    .som-control.lg {
        display: initial;
    }
}
