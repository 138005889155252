@import "/src/theme.scss";

.c-tb-text-button {
    border: none;
    background: none;
    color: $grey-5;
    text-decoration: none;
}

a.c-tb-text-button {
    margin: auto 0;
}
