@keyframes placeHolderShimmer {
    0% {
        background-position: -46.8rem 0;
    }

    100% {
        background-position: 46.8rem 0;
    }
}

.c-sk-animated-background {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 80rem 10.4rem;
    position: relative;
}
