$font-family: "Montserrat" !important;
$font-family-1: "poppins";
$title-heading-font-size: 1.7rem;
$title-heading-font-weight: 700;
$font-size-12: 1.2rem;
$font-size-13: 1.3rem;
$font-size-14: 1.4rem;
$font-size-15: 1.5rem;
$body-box-shadow: 0.1rem 0 1rem rgb(0 0 0 / 30%);
$blue-0: #dbf0fa;
$blue-1: #6fc2eb;
$blue-2: #0e67de;
$blue-3: #092e41;
$green-0: #e3f1d4;
$green-1: #cee6b3;
$green-2: #90c652;
$green-3: #547a29;
$green-4: #47c447;
$red-0: #fbcbcb;
$red-1: #f68e8e;
$red-2: #ee2f2f;
$red-3: #840b0b;
$yellow-0: #ffeec0;
$yellow-1: #fee49a;
$yellow-2: #feba02;
$yellow-3: #cb9501;
$grey-0: #dee2e6;
$grey-1: #ced4da;
$grey-2: #adb5bd;
$grey-3: #6c757d;
$grey-4: #343a40;
$grey-5: #968c8c;
$gray-97: #f7f7f7;
$grey-98: #f8f9fa;
$height-63: 63%;
$height-10: 10%;
$height-8: 8%;

@mixin important-text {
    color: red;
    font-size: 2.5rem;
    font-weight: bold;
    border: 0.1rem solid blue;
}

@mixin product-name {
    font-size: $font-size-14;
    margin-top: 0.5rem;
}

@mixin gc-title {
    height: 46%;
    border-radius: 3px;
    border: 0px solid #e4d7d7;
}
@mixin heading_giftvoucher {
    color: #3b3b3d;
    margin-bottom: 7px;
    font-size: $font-size-15;
    font-weight: 600;
}

@mixin text-center {
    text-align: center;
}

@mixin coupon_code {
    display: inline-block;
    width: 32%;
    font-size: 10px;
    text-align: center;
    padding-top: 4%;
    border: none;
    font-family: $font-family;
}
