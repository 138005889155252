@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "global";
@import "common";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "./app/controls/controls.module.scss";

body {
    font-family: $font-family-1;
}

b,
strong {
    font-weight: 700;
}

.html-class {
    -webkit-overflow-scrolling: touch !important;
    //overflow: auto;
    height: 100%;
}

body {
    font-family: "poppins";
    //position: unset;
}

body.html-class {
    height: 100vh;
}

.md-container {
    margin: 0 auto;
    padding: 0 16px;
    max-width: 1220px;
    width: 100%;
}

@font-face {
    font-display: swap;
    font-family: Ionicons;
    src: url(assets/fonts/ionicons.eot?v=2.0.1);
    src: url(assets/fonts/ionicons.eot?v=2.0.1#iefix)
            format("embedded-opentype"),
        url(assets/fonts/ionicons.ttf?v=2.0.1) format("truetype"),
        url(assets/fonts/ionicons.woff?v=2.0.1) format("woff"),
        url(assets/fonts/ionicons.woff) format("woff"),
        url(assets/fonts/ionicons.svg?v=2.0.1#Ionicons) format("svg");
    font-weight: 400;
    font-style: normal;
}

.ion-chevron-right:before {
    content: "";
    display: inline-block;
    font-family: Ionicons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cate-date {
    position: absolute;
    right: 0.5rem;
    margin-top: 0.5rem;
    background: #fff;
    font-size: 3.5rem;
    padding: 0 0.6rem;
    border-top: solid 1.8rem $blue-3;
    border-radius: 0.5rem;
    top: 0;
    z-index: 2;
}

.event_date {
    text-align: center;
    float: left;
    margin-top: -1.8rem;
}

.sap {
    font-size: 4rem;
    line-height: 3rem;
    color: $blue-3;
    float: left;
    padding: 0 0.5rem;
    margin-top: 0.5rem;
}

span.day {
    color: #fff;
    text-transform: none;
    font-weight: 400;
    font-size: 1.2rem;
    text-align: center;
    width: 100%;
    display: block;
}

span.date-month {
    font-size: 2.6rem;
    line-height: 2rem;
    font-weight: 700;
    color: $blue-3;
    text-transform: uppercase;
    display: block;
    margin-top: 0.2rem;
    margin-left: -0.2rem;
}

span.year {
    font-size: 1.243rem;
    font-weight: 600;
    display: block;
    color: $blue-3;
}

#phone {
    opacity: 0.4 !important;
    border: 0;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -46.8rem 0;
    }

    100% {
        background-position: 46.8rem 0;
    }
}

.animated-background {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 80rem 10.4rem;
    position: relative;
}

.height-63 {
    height: $height-63;
}

.height-10 {
    height: $height-10;
}

.height-8 {
    height: $height-8;
}

span.year {
    font-size: 1.243rem;
    font-weight: 600;
    display: block;
    color: $blue-3;
}

.custom-home-screen-div-21 {
    height: 100%;
    width: 87%;
    display: flex;
    flex: 0 0 auto;
    flex-flow: column;
    border-radius: 0.5rem;
    justify-content: space-between;
    padding-left: 1rem;
}

button:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
}

.dummy-category-listing-item {
    display: flex;
    background: #fff;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.dummy-category-listing-single-item {
    height: 25%;
    width: 100%;
}

.dummy-category-listing-single-item-img {
    height: 111%;
}

.dummy-category-listing-single-item-fulldesc {
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.dummy-category-listing-single-item-name {
    height: 20%;
    width: 100%;
}

.dummy-category-listing-single-item-desc {
    height: 20%;
}

.dummy-category-listing-single-item-locprice {
    height: 18%;
    display: flex;
    justify-content: space-between;
}

.dummy-category-listing-single-item-loc {
    height: 100%;
    width: 41%;
}

.dummy-category-listing-single-item-price {
    height: 100%;
    width: 35%;
}

.no-product {
    font-size: 1.8rem;
    padding: 1rem 0;
    background-color: $gray-97;
    justify-content: center;
    text-align: center;
    color: $grey-4;
}

.bg-danger {
    background-color: $grey-0 !important;
}

/**
Login CSS
 */

.main-div {
    width: 100%;
    background: $gray-97;
}

//.card {
//    background: inherit;
//    border: none;
//
//    .card-body {
//.main-div {
//    height: calc(100vh - 4.5rem);
//    width: 100%;
//    background: $gray-97;
//}
.card {
    background: inherit;
    border: none;
    .card-body {
        padding: 0 1rem;
    }
    form {
        margin-top: 3rem;
        .form-group {
            position: relative;
            margin-bottom: 2rem;
            label {
                position: absolute;
                top: 1.5rem;
                opacity: 0;
                transition: opacity 0.15s ease-in, top 0.2s linear;
                font-size: 1.3rem;
            }
            label.has-input {
                opacity: unset;
                transition: opacity 0.15s ease-in, top 0.2s linear;
                top: -1.8rem;
            }
            input {
                &:focus {
                    box-shadow: none;
                }
                &::placeholder {
                    opacity: 0.3;
                    color: $blue-3 !important;
                    font-size: 1.3rem;
                }
            }
            button {
                border-radius: 1.5rem;
                // height: 5rem!important;
                font-size: 1.4rem;
                font-weight: 600;
                background-color: $blue-2;
                border-color: $blue-2;
                color: #fff;
                width: 100%;
                line-height: 30px;
                padding: 5px 10px;
            }
            .showandhidepass {
                position: absolute;
                top: 0.2rem;
                right: 6px;
                font-size: 2.5rem;
                color: $grey-2;
            }
            .iti.separate-dial-code {
                width: 100%;
                .iti__selected-flag {
                    padding: 0;
                    background: transparent !important;
                    .selected-dial-code {
                        margin: 0;
                        font-size: 1.4rem;
                        font-weight: 500;
                        padding: 0 0 0 5px;
                        color: $blue-3;
                    }
                }
            }
        }
        .term-content {
            margin-top: 2rem;
            font-size: 1.5rem;
            color: $grey-2;
            font-weight: 400;

            line-height: normal;
            a {
                color: $blue-2;
            }
        }
    }
    .forget-pass-new-acc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 4rem;
        text-decoration: underline;
        color: $blue-2;
        font-size: 1.4rem;

        font-weight: 400;
        margin-top: -0.5rem;
    }
    .continue-with-box {
        .social-button {
            background-color: $blue-2;
            width: 100%;
            height: 4.5rem;
            background-color: white;
            border: 0.1rem solid $grey-1;
            border-radius: 0.5rem;
            padding: 0.5rem 1rem;
            .social-display {
                display: flex !important;
                flex-flow: row nowrap;
                float: left;
                line-height: 20px;
                .social-img {
                    height: 2.5rem;
                    width: 2.5rem;
                    margin-left: 0.8rem;
                    margin-top: -0.2rem;
                }
                .social-login {
                    padding-top: 0rem;
                    width: 94%;
                    margin-left: 0rem;
                    color: $blue-3;
                    position: absolute;
                    font-size: 3.8vw;
                    font-weight: 550;
                }
            }
        }
    }
}
.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .popup {
        background: #fff;
        padding: 2rem;
        width: 90%;
        height: fit-content;
        border-radius: 1rem;
        animation-name: superScaleIn;
        animation-duration: 0.2s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: both;
        @keyframes superScaleIn {
            from {
                transform: scale(1.2);
                opacity: 0;
            }
            to {
                transform: scale(1);
                opacity: 1;
            }
        }
        .popup-body {
            padding: 1rem 1rem 0.5rem;
            font-size: 1.4rem;
            font-weight: 400;
            color: $grey-3;
        }
        .popup-buttons {
            padding: 1rem;
            padding-top: 0;
            .popup-close-btn {
                width: 100%;
                background: $blue-2;
                color: #fff;
                font-size: 1.3rem;
                padding: 0.5rem 1rem;
                line-height: 3.5rem;
                border-radius: 0.4rem;
                border: 0.1rem solid transparent;
                height: 4.5rem;
            }
        }
    }
}
.popup-container.mobile-after-api-call-error {
    .popup {
        width: 90%;
        .popup-buttons {
            button {
                color: #fff;
                font-weight: 600;
                font-size: 1.8rem;
            }
        }
    }
}
.display-block {
    display: block !important;
}
.display-none {
    display: none !important;
}

.swiper-pagination-bullet {
    background: #fff !important;
    opacity: 0.2 !important;
}
.swiper-pagination-bullet-active {
    opacity: 0.56 !important;
}

/* Notification component css */
.notification-component-parent-div {
    .notification-for-loop:nth-last-child(1) {
        margin-bottom: 3rem;
    }
    .row-1,
    .row-2,
    .row-3 {
        display: flex;
        justify-content: space-between;
        width: 99%;
        > div {
            display: flex;
            width: 100%;
            text-decoration: none !important;
            box-shadow: 0rem 0.15rem 0.4rem 0rem rgba(0, 0, 0, 0.2);
            min-height: 12rem;
            margin-top: 1rem;
            .column-1.notification-image {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                img {
                    height: 12rem;
                    width: 12rem;
                    object-fit: cover;
                    margin: 0.5rem 0;
                }
            }
            .column-2 {
                padding: 0.7rem 0.5rem 1.2rem 1.5rem;
                width: 100%;
                position: relative;
                .notification-title {
                    width: 100%;
                    font-weight: 700;
                    color: $blue-3;
                    font-size: 1.4rem;
                    text-align: left;
                    text-transform: capitalize;
                    margin-bottom: 0.3rem;
                }
                .notification-desc {
                    width: 100%;
                    text-align: left;
                    white-space: normal;
                    color: $blue-2;
                    font-size: 1.2rem !important;
                    font-weight: 500;
                    margin-top: 0.2rem;
                    overflow: hidden;
                    line-height: 1.2em;
                    margin-bottom: 4rem;
                    word-break: break-word;
                }
                .inner-row-1 {
                    display: flex;
                    justify-content: space-between;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding: 1.2rem 0rem 0rem 1.5rem;
                    .inner-col-1 {
                        text-align: left;
                        display: flex;
                        align-items: center;
                        h6 {
                            margin: 0;
                            font-weight: 400;
                            color: $grey-3;
                            font-size: 1.1rem;
                            text-align: left;
                            overflow: hidden;
                            display: table-cell;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    .inner-col-2 {
                        text-align: right;
                        display: flex;
                        align-items: center;
                        margin-right: 10px;
                        a {
                            margin: 0;
                            font-weight: 600;
                            color: $blue-2;
                            font-size: 1.4rem;
                            text-align: left;
                            overflow: hidden;
                            display: table-cell;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
    .product-listing-location {
        width: 100%;
        max-height: 19px;
        font-weight: 400;
        color: $grey-3;
        font-size: 11px;
        text-align: left;
        overflow: hidden;
        display: table-cell;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .prd-lstng-review {
        width: 60%;
        float: left;
        position: absolute;
    }
    .custom-notification-div-5 {
        float: left;
        width: 48%;
        bottom: 5px;
    }

    .row-2 .column-2 .inner-row-1 {
        padding-bottom: 0.2rem !important;
    }
    .row-2 .column-2 .inner-row-1 .inner-col-1 {
        display: block;
    }
    .row-2 .column-2 .inner-row-1 .inner-col-2 {
        margin-right: 0.2rem;
    }
    .row-2 .column-1.notification-image img {
        margin: 0 !important;
    }
    .userNotLogin {
        h2 {
            padding: 1.5rem 0;
            font-size: 1.8rem;
            text-align: center;
            color: $grey-4;
            opacity: 0.8;
        }
    }
}
/* End Notification component css */

/* Store credit component css */
.store-credit-component-parent-div {
    .store-credit-balance {
        padding: 1.5rem 0;
        text-align: center;
        color: $blue-2;
        margin: 0;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 700;
    }
    .sc-bal-amt {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        .credit_amount {
            margin: 0;
            background: #d2e6f9;
            border: none;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 9px 12px 6px 12px;
            font-size: 3rem;
            line-height: 3rem;
            color: $blue-2;
            font-weight: 700;
        }
    }
    .transaction-titles {
        width: 100%;
        .thead {
            display: flex;
            justify-content: space-between;
            .th h3 {
                font-weight: 800;
                color: $blue-3;
            }
            .th.date-title {
                width: 20%;
                h3 {
                    padding-left: 20px;
                }
            }
            .th.description-title {
                width: 34%;
                h3 {
                    padding-left: 18px;
                }
            }
            .th.changed-amount-title {
                width: 25%;
                h3 {
                    padding-left: 10px;
                }
            }
            .th.new-balance-title {
                width: 20%;
                h3 {
                    padding-right: 20px;
                }
            }
        }
        .tbody {
            .transaction-array .transaction .tr {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 96%;
                margin: auto;
                padding: 10px 5px;
                border-radius: 10px;
                margin-top: 15px;
                .td {
                    text-align: center;
                }
                .td.date {
                    width: 16%;
                    .trans-date {
                        margin: 0;
                        color: $blue-3;
                        font-size: 1.4rem;
                        font-weight: 800;
                    }
                }
                .td.description {
                    width: 34%;
                    .trans-desc {
                        margin: 0;
                        color: $blue-3;
                        font-weight: 500;
                        font-style: italic;
                        font-size: 1.3rem;
                    }
                    .admin-comment {
                        margin: 0;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: $blue-3;
                        font-style: italic;
                        font-weight: 700;
                        font-size: 1.4rem;
                        line-height: 1.8rem;
                    }
                }
                .td.changed-amount {
                    width: 25%;
                    padding-left: 15px;
                    .trans-changed-amt {
                        margin: 0;
                        font-weight: 800;
                        font-size: 1.4rem;
                    }
                }
                .td.new-balance {
                    width: 25%;
                    padding-left: 15px;
                    .trans-updated-balance {
                        margin: 0;
                        font-size: 1.4rem;
                        font-weight: 800;
                        color: $blue-3;
                    }
                }
            }
            .transaction-array .transaction .tr.green-trans-color {
                background: $green-0;
                border: none !important;
                .td.changed-amount {
                    .trans-changed-amt {
                        color: $green-3;
                    }
                }
            }
            .transaction-array .transaction .tr.red-trans-color {
                background: $red-0;
                border: none !important;
                .td.changed-amount {
                    .trans-changed-amt {
                        color: $red-2;
                    }
                }
            }
        }
    }
}
/* End store credit component css */

/* Reviews component css */
.reviews-component-parent-div {
    .custom-user-review-div-1 {
        padding-top: 1.2rem !important;
        background: #fff;
        padding-bottom: 2rem;
        min-height: inherit;
    }
    .custom-user-review-div-2 {
        margin: 0 0.8rem;
    }
    .custom-user-review-div-3 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .custom-user-review-div-4 {
        width: 7.5rem;
        height: 7.5rem;
    }
    .product-image1 {
        display: inline-block;
        vertical-align: top;
        width: 8rem;
        object-fit: cover;
        justify-content: center;
    }
    .custom-user-review-img-1 {
        width: 7.5rem !important;
        height: 100% !important;
        border-radius: 0.1rem;
        object-fit: cover;
    }
    .custom-user-review-div-5 {
        color: $blue-3;
        font-weight: 500;
        font-size: 1.2rem;
        font-style: normal;
        white-space: normal;
        margin-bottom: 0.5rem;
        text-align: center;
    }
    .custom-user-review-div-6 {
        color: $blue-3;
        font-weight: 500;
        font-size: 1.3rem;
        font-style: normal;
        white-space: normal;
        margin-bottom: 0.5rem;
    }
    .custom-user-review-div-7 {
        background: $blue-0;
        height: 1.4rem;
        border-radius: 1.4rem;
    }
    .custom-user-review-div-8 {
        height: 1.4rem;
        position: relative;
        color: $blue-0;
        font-size: 5rem;
        border-radius: 1.4rem;
        margin: 0.1rem;
        width: 100%;
        background-color: $yellow-2;
    }
    .progress {
        position: relative;
        margin: 0.4rem;
        float: left;
        text-align: center;
        color: $blue-0;
        font-size: 5rem;
    }
    .custom-user-review-div-9 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
    }
    .custom-user-review-div {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        color: $blue-3;
    }
    .custom-user-review-div-text {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        color: $yellow-2;
    }
    .custom-user-review-div-16 {
        box-sizing: border-box;
        border-radius: 1rem;
        position: relative;
        margin: 0 1rem;
        background: #fff;
        box-shadow: 0 0 0.5rem rgb(0 0 0 / 5%);
        // padding-bottom:  2rem ;
        margin-bottom: 30px;
    }
    .custom-user-review-div-17 {
        position: absolute;
        margin-left: 75%;
        top: -7vw;
        text-align: center;
        width: 11vw;
        height: 11vw;
    }
    .reviewEmoji {
        background-color: #fff;
        width: 11vw;
        height: 11vw;
        border-radius: 100%;
        top: -15px;
        margin-bottom: 0.5rem;
        box-shadow: 0 0 0.5rem 0 rgb(0 0 0 / 20%);
        -webkit-box-shadow: 0 0 0.5rem 0 rgb(0 0 0 / 20%);
    }
    .custom-user-review-div-18 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 25px 10px;
    }
    .custom-user-review-div-19 {
        padding-left: 1rem;
    }
    .custom-user-review-div-20 {
        color: $blue-3;
        font-weight: 600;
        font-size: 1.7rem;
        font-style: normal;
        white-space: nowrap;
        margin-bottom: 1.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80vw;
    }
    .custom-user-review-div-21 {
        font-style: normal;
        font-weight: 600;
        font-size: 1.2rem;
        color: $blue-2;
        margin-bottom: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-height: 1.4;
        word-break: break-word;
        width: 90%;
    }
    .custom-user-review-div-22 {
        background: $blue-2;
        border-radius: 1rem;
        width: 15rem;
        height: 4.5rem !important;
        font-size: 1.5rem !important;
        margin-top: 1rem;
        padding-bottom: 1.5rem;
        text-align: center;
        padding: 0.9rem 0;
        color: #fff;
    }
}
/* End Reviews component css */

.width-100 {
    width: 100%;
}
.height-42 {
    height: 42px;
}
.height-80 {
    height: 80px;
}
.height-51 {
    height: 51px;
}
.facility-span {
    flex-basis: 50%;
    min-width: 50%;
    flex: 1;
    font-size: 13px;
    color: $blue-3;
    font-weight: 600;
    padding-bottom: 8px;
    padding-top: 8px;
    display: flex;
    align-items: center;
}

img.facility-image {
    float: left;
    width: 30px !important;
    height: 30px !important;
}
.facility-name-qty {
    vertical-align: middle;
    margin-left: 8px;
}

.show_all_fac_btn {
    background-color: #fff;
    border: solid 1px $blue-2;
    border-radius: 5rem;
    color: $blue-2;
    font-weight: 600;
    padding: 5px 10px;
    font-size: 12px;
    margin-bottom: 10px !important;
    margin-top: 10px;
    line-height: 24px !important;
}
.room-main.row.room-category {
    height: auto;
    max-width: 100%;
    display: inline-block;
    margin-right: 15px !important;
    margin-right: 40px;
}
.room-main {
    box-shadow: 0 0 8px rgb(0 0 0 / 20%);
    border-radius: 10px;
    margin-bottom: 20px !important;
    margin-right: 3px !important;
    margin-left: 3px !important;
    width: 85%;
}
.room-main .room-img {
    padding: 0;
    position: relative;
    width: 300px;
    height: 200px;
    transform: translateZ(0);
}
img.room-type-img {
    height: 200px !important;
    width: 100% !important;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-bottomleft: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
.room-facility .room-name {
    font-size: 20px;
    font-weight: 700;
    color: $blue-3;
    letter-spacing: 1px;
    margin: 15px 8px 10px;
    white-space: pre-wrap;
}
.room-inner {
    flex-basis: 50%;
    width: 50%;
    flex: 1;
    font-size: 12px;
    color: $blue-3;
    font-weight: 600;
    display: flex;
    padding: 8px 5px;
    white-space: normal;
    align-items: flex-start;
    flex: 0 0 50% !important;
    max-width: 50%;
}
.room-inner img {
    width: 26px !important;
    height: 26px !important;
    margin-right: 5px;
}
.room-category-container {
    display: flex;
    height: auto;
    width: auto;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

#map-block .map-outer-block {
    padding: 10px;
}
#price-option-outer,
.each-review-block,
.pro-info-text {
    background-color: #fff;
}
#map-block iframe {
    display: block;
    width: 100%;
}
#detail-block .pro-info-heading,
#info-block .pro-info-heading,
#map-block .pro-info-heading,
#review-block .pro-info-heading {
    display: table;
    width: 100%;
    overflow: hidden;
    height: 35px;
    background-color: #fff;
    color: $grey-4;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 5px;
}
#detail-block,
#info-block,
#map-block,
#review-block {
    display: block;
    width: 100%;
    overflow: hidden;
    padding: 0;
    box-shadow: 0 1px 3px 0rgb (0 0 0 / 30%);
}

/**
CSS for Skeleton PDP
 */

.dummy-pd-name-desc {
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    height: 10%;
    background: #fff;
    margin-top: 2%;
}

.dummy-pd-name {
    height: 47%;
}

.dummy-pd-desc {
    height: 100%;
}

.dummy-pd-image-share {
    display: flex;
    flex-flow: column;
    height: 43%;
    justify-content: space-between;
}

.dummy-pd-img {
    height: 100%;
    width: 100%;
}

.dummy-pd {
    height: 94vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 3px;
}

.dummy-pd-price-occup {
    height: 54%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.dummy-pd-occup {
    height: 85%;
}

.dummy-pd-price {
    height: 15%;
}

.dummy-pd-occup {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.dummy-pd-occup-title {
    height: 16%;
}

.dummy-pd-book-now {
    height: 25%;
    display: flex;
    justify-content: space-around;
    background: #eee;
    box-sizing: border-box;
    border: 1px solid #eee;
}

.dummy-pd-book-now-price {
    top: 27%;
    width: 23%;
    height: 56%;
    box-sizing: border-box;
    border: 4px solid #fff;
    border-radius: 8px;
}

.dummy-pd-book-now-button {
    top: 27%;
    width: 31%;
    height: 56%;
    box-sizing: border-box;
    border: 4px solid #fff;
    border-radius: 8px;
}

.dummy-pd-box {
    margin-top: 2%;
    height: 24%;
    border-radius: 100%;
    width: 4%;
}

.dummy-pd-occup-option {
    height: 24.5%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    border: 1px solid #eee;
}

.dummy-pd-occup-desc {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.dummy-pd-occup-price {
    top: 7%;
    height: 85%;
    width: 20%;
    border-radius: 4px;
}

.dummy-pd-occup-desc-room-type {
    height: 38%;
    border-radius: 4px;
}

.dummy-pd-occup-desc-occup-type {
    height: 38%;
}

#adv_search_bar_outer {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 13px;
}
.adv_search__bar_each_block {
    display: block;
    position: relative;
    float: left;
    text-align: left;
    padding-left: 12px;
    top: 5px;
    margin-left: -5px;
}
img.adv_searchbar_img {
    position: absolute;
    width: 16px;
    top: 2px;
    left: 11px;
}
.adv_searchbar_label {
    padding: 2px 0 0 21px !important;
    //text-overflow: ellipsis;
    //overflow: hidden;
    white-space: nowrap;
    font-size: 11px;
    color: $grey-4;
}
.item {
    border-color: $grey-1;
    color: $grey-4;
    font-size: 14px;
}

.item-input {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 6px 0 5px 16px;
}
td.single-selected span {
    position: absolute;
    width: 39px;
    height: 39px;
    display: block;
    padding: 10px;
    top: 0;
    left: 0;
    border-radius: 50px !important;
    background-color: $blue-3;
    color: #fff !important;
}
.single_date_background {
    background-color: transparent !important;
}
input,
textarea,
input#phone {
    border-radius: 10px;
    color: $blue-3;
    font-size: 1.3rem;
    font-weight: 400;
    background: $blue-0;
    height: 35px;
    padding: 5px 10px;
    line-height: 35px;
    width: 100%;
    &::placeholder {
        font-size: 1.3rem;
        letter-spacing: 0;
    }
    &:focus-visible {
        outline: none;
        box-shadow: none;
    }
}
input[type="radio"],
input[type="checkbox"] {
    height: auto;
    width: auto;
}
textarea {
    padding: 8px 10px 25px 5px;
    border-radius: 5px;
    border: none;
}
select {
    background: $blue-0;
    color: $blue-3;
    border-radius: 10px;
    opacity: 1;
    font-weight: 400;
    border: none;
    &:focus-visible {
        outline: none;
        box-shadow: none;
    }
}

.divLikeInput {
    border: none !important;
    border-radius: 5px !important;
    color: $blue-3 !important;
    padding: 5px 10px !important;
    background: $blue-0 !important;
    height: 35px;
    line-height: 23px;
}

button,
a {
    border-radius: 5rem;
}
.divLikeButton {
    border-radius: 5rem !important;
    height: 45px !important;
    padding: 5px 10px !important;
    line-height: 35px;
}

.ripleEffect {
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}

.ripleEffect:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $blue-0 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
}

.ripleEffect:active:after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
}

.button:active::after,
.ripple:active,
.button:active + .ripple {
    animation: none;
}

.noGlobalCssForInput {
    border: none !important;
    background: initial !important;
    padding: initial !important;
}

.ngx-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.ngx-spinner-overlay > div:not(.loading-text) {
    top: 50%;
    left: 50%;
    margin: 0;
    position: absolute;
    transform: translate(-50%, -50%);
}

.custom-red-border {
    border: 1px solid $red-2 !important;
}
// .offcanvas-backdrop.fade.show{
//     display: none;
// }
// .offcanvas-backdrop.fade.show.custom{
//     display: block;
// }

/*
Upcoming TRIP CSS home page
*/

.upcoming-trips-div {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 135px;
    z-index: 1;
    background: #ffffff;
    box-shadow: 0px -6px 14px rgb(0 0 0 / 30%);
    border-radius: 20px 20px 0px 0px;
    padding: 15px;
    display: flex;
}

.upcoming-trips-sub-div {
    padding-left: 20px;
}

.upcoming-trips-div .left-img {
    height: auto;
    width: 100%;
    border: 1px solid;
    border-radius: 20px;
    max-width: 111px;
}

.upcoming-trips-div-heading {
    font-weight: 500;
    font-size: 16px;
    color: $blue-2;
}

.upcoming-trips-div-sub-heading {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: $blue-3;
    margin-top: 4px;
}

.upcoming-trips-div-map {
    margin-top: 7px;
    color: $grey-3;
}

.upcoming-trips-div-checkin-checkout {
    font-weight: 600;
    margin-top: 10px;
}

.upcoming-trips-div .vector-img {
    margin-top: 7px;
    position: absolute;
    right: 20px;
    width: 17px;
}

.upcoming-trips-div .default-img {
    border-radius: 15px;
    height: 100%;
    width: 100px;
}

@media (min-width: 300px) and (max-width: 400px) {
    .upcoming-trips-div-heading {
        font-size: 15px;
    }
}

/*
End
*/

.custom-user-review-div-23 {
    background-image: url("assets/img/ic_stamp_view_more.png");
    background-repeat: no-repeat;
    background-size: 35px 18px;
    background-position: center right;
    margin: 20px 20px;
    animation: mymove 0.25s infinite;
}

.view_more {
    text-align: center;
    font-size: 16px;
    color: $blue-2;
}

.custom-product-detail-div-44 {
    height: 332px;
}
div#price-option-outer .calenderimg .calender_outer {
    border-right: 1px solid $red-0;
    border-left: 1px solid $red-0;
}
.calender_outer {
    padding-bottom: 70px;
}

#cal-header-block-outer,
#checkin_checkout_block_home,
#filter-modal-footer,
#home_categories_block_outer,
#occupancy_block_home_outer,
#price-option-outer,
.bestseller_cat_heading,
.bestseller_category_outer_div,
.bestseller_outer_div,
.calender_outer,
.option-list-block label {
    display: block;
    width: 100%;
    overflow: hidden;
}
div#price-option-outer .calenderimg .arrivaltext {
    font-weight: 500;
    padding: 10px 10px 10px;
}

.arrivaltext {
    text-align: center;
    font-size: 18px;
    padding: 25px 10px 10px;
    font-weight: 700;
}
div#price-option-outer .calenderimg .calender_outer .calenderline {
    background-color: $red-0;
}
.calenderline {
    width: 20%;
    height: 2px;
    background-color: $grey-2;
    vertical-align: middle;
    display: inline-block;
}
div#price-option-outer .calenderimg .calender_outer .calenderline {
    background-color: $red-0;
}
.calenderline {
    width: 20%;
    height: 2px;
    background-color: $grey-2;
    vertical-align: middle;
    display: inline-block;
}
table.each_month_table {
    width: 86%;
    margin-left: 7%;
    overflow: hidden;
    text-align: center;
    vertical-align: middle;
    max-width: 100%;
    background-color: transparent;
    text-transform: uppercase;
}
div#price-option-outer
    .calenderimg
    .calender_outer
    .each_month_table
    > caption {
    background: $blue-2;
    color: #fff;
}
.each_month_table > caption {
    height: 40px;
    vertical-align: middle;
    display: table-caption;
    padding-top: 0px;
    color: $blue-3;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    text-transform: none;
    margin-bottom: 0px;
}
// .custom-product-detail-caption-1 {
//     padding-top: 10px;
//     font-size: 16px;
//     text-align: center;
// }
tr.calender_day_row {
    background-color: $red-0;
    font-size: 10px;
    color: $grey-3;
    font-weight: 500;
}
div#price-option-outer .calenderimg .calender_outer tr.calender_day_row th {
    text-align: center;
}
.calender-text-muted.monthview-disabled.secondaryMonth {
    background-color: #fff;
    color: #fff !important;
}
.each_month_table > tbody > tr > td {
    text-align: center !important;
    font-weight: 600 !important;
    position: relative;
    width: 39px;
    height: 39px;
    font-size: 14px;
    padding: 8px;
    text-align: center !important;
    color: $blue-3;
}
.calender-text-muted-secondary-date {
    background-color: transparent !important;
    color: $grey-3 !important;
    cursor: not-allowed !important;
    text-decoration: line-through;
}

#cal-modal-special-block {
    color: $grey-4;
    display: block;
    overflow: hidden;
    width: 100%;
    padding: 5px 15px;
    // background-color: #fff;
}
#cal-modal-special-block .first-block {
    display: flex;
    justify-content: space-between;
}
#cal-modal-special-block .cross {
    font-weight: 600;
    padding: 4px;
}
#cal-modal-special-block .offer-options {
    margin-top: 5px;
}
.min_night_header {
    display: inline;
    vertical-align: top;
    font-size: 12px;
    font-weight: 600;
}
#cal-modal-special-block .free-night {
    color: $red-2;
    font-weight: 600;
}
.discount_label {
    margin-bottom: 4px;
    padding: 4px;
    margin-left: 3%;
    font-size: 12px;
    color: $blue-3;
    border-radius: 5px;
    text-align: center;
    margin-right: 3px;
}
#cal-modal-special-block .heading {
    background-color: $red-2;
    color: #fff;
    display: inline;
    font-size: 16px;
    font-weight: 600;
}

#cal-modal-special-block ul {
    list-style: none;
    padding: 0;
}
.dropdown-item.active,
.dropdown-item:hover,
.dropdown-item:focus,
.sidebar-component-parent-div a:hover,
.sidebar-component-parent-div a:focus,
.sidebar-component-parent-div a:active {
    background-color: $grey-2;
    border-radius: 0 !important;
}

.fa-search:before {
    margin-left: 4px;
}

.lang-menu {
    text-align: right;
    font-weight: bold;
    position: relative;
    left: 0px;
}
.lang-menu .selected-lang:before {
    content: "";
    display: inline-block;
    width: 32px;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
}

.lang-menu ul {
    margin: 0;
    padding: 0;
    display: none;
    background-color: #fff;
    position: absolute;
    top: 20px;
    right: 0px;
    left: -1px;
    width: 90px;
    border-radius: 5px;
    box-shadow: 0px 1px 10px rgb(0 0 0 / 20%);
}

.lang-menu ul li {
    list-style: none;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

a.custom-design-before:before {
    width: auto !important;
    margin-right: 0px !important;
}

.lang-menu ul li a {
    text-decoration: none;
    width: 100%;
    padding: 5px 10px;
    display: block;
    height: 26px;
}

.lang-menu ul li:hover {
    background-color: $red-0;
}

.lang-menu ul li a:before {
    content: "";
    display: inline-block;
    width: 22px;
    height: 22px;
    vertical-align: middle;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
}

//Sidebar language CSS

.lang-menu-s {
    text-align: right;
    font-weight: bold;
    position: relative;
    left: 0px;
}
.selected-lang-s {
    height: 17px !important;
    width: 25px !important;
}
.selected-lang-s:before {
    content: "";
    display: inline-block;
    width: 32px;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
}

.lang-menu-s ul {
    margin: 0;
    padding: 0;
    background-color: #fff;
    border: 1px solid $red-0;
    position: absolute;
    top: 20px;
    right: 0px;
    left: -1px;
    width: 70px;
    border-radius: 5px;
    box-shadow: 0px 1px 10px rgb(0 0 0 / 20%);
}

.lang-menu-s ul li {
    list-style: none;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.lang-menu-s ul li a {
    text-decoration: none;
    width: 125px !important;
    padding: 5px 10px;
    display: block !important;
    height: 26px;
}

.lang-menu-s ul li:hover {
    background-color: $grey-0;
}

.lang-menu-s ul li a:before {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align: middle;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
}

// End Sidebar language CSS

.en-,
.fr- {
    background-size: cover !important;
    height: 17px !important;
}

.en- {
    background: url(assets/img/flag-of-mauritius-logo.png) no-repeat center
        center;
}

.fr- {
    background: url(assets/img/flag-of-mauritius-logo.png) no-repeat center
        center;
}
.en:before {
    background-image: url(assets/img/flag-of-mauritius-logo.png);
}
.fr:before {
    background-image: url(assets/img/flag-of-mauritius-logo.png);
}
.fr-reunion:before {
    background-image: url(assets/img/Reunion-logo.png);
}

.lang-menu:hover ul {
    display: block;
}

.lang-menu ul li .lang-right-sec span {
    position: absolute;
    color: $blue-2;
    margin-left: 15px;
    margin-top: 4px;
    font-size: 10px;
}

.lang-menu ul li a span {
    position: absolute;
    vertical-align: middle;
    color: $blue-2;
    font-size: 10px;
}

.lang-left-sec {
    float: left;
    width: 37.5px;
}

.lang-right-sec {
    float: right;
    width: 37.5px;
}

.ngb-dp-months {
    display: inline-grid !important;
}
.ngb-dp-month .ngb-dp-week {
    display: flex;
    justify-content: center;
}
.input-group {
    ngb-datepicker-month {
        width: 80vw;
        display: block;
    }
}

/* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';

button.calendar,
button.calendar:active {
    width: 2.75rem;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=") !important;
    background-repeat: no-repeat;
    background-size: 23px;
    background-position: center;
}

/* Sortable table demo */

th[sortable] {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
}

th[sortable].desc:before,
th[sortable].asc:before {
    content: "";
    display: block;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC")
        no-repeat;
    background-size: 22px;
    width: 22px;
    height: 22px;
    float: left;
    margin-left: -22px;
}

th[sortable].desc:before {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
}

/* Filtering table demo */
ngbd-table-filtering span.ngb-highlight {
    background-color: $yellow-2;
}

/* Complete table demo */
ngbd-table-complete span.ngb-highlight {
    background-color: $yellow-2;
}

ngb-carousel .picsum-img-wrapper {
    position: relative;
    height: 0;
    padding-top: 55%; /* Keep ratio for 900x500 images */
}

ngb-carousel .picsum-img-wrapper > img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.custom-input-btn {
    height: 34px;
    border: 0;
    left: 5px;
}

.order-skeleton {
    margin-bottom: 10px;
}
.order-skeleton-div {
    height: 20px;
    width: 100%;
    margin-bottom: 10px;
}
.order-skeleton-div-1 {
    width: 69%;
    height: fit-content;
    display: flex;
    flex-wrap: nowrap;
}
.order-skeleton-div-2 {
    width: 130px;
    height: 130px;
    margin-right: 10px;
}
.order-skeleton-div-3 {
    display: flex;
    padding: 0;
    align-content: space-between;
    flex-wrap: wrap;
}
.order-skeleton-div-4 {
    width: 100%;
    height: 20px;
}
.cart-pro-delete_button_cal {
    display: block;
    overflow: hidden;
    position: absolute;
    top: 14px;
    right: 13px;
}

.close-button {
    font-size: 1.4rem !important;
    color: $grey-3 !important;
    background-color: transparent !important;
    border-radius: 10px !important;
    padding: 10px 7px !important;
    line-height: 0px !important;
    font-weight: 700 !important;
    border: 0;
}

.request-date-input {
    border: none !important;
    padding: 5px 10px;
    line-height: 35px;
    background: $blue-0 !important;
    border-radius: 10px !important;
}

@media (min-width: 300px) and (max-width: 400px) {
    .register-component-parent-div
        .ng-otp-input-wrapper
        .otp-input:not(:last-child) {
        margin-right: 8px !important;
    }
}
@media (min-width: 400px) and (max-width: 420px) {
    .register-component-parent-div
        .ng-otp-input-wrapper
        .otp-input:not(:last-child) {
        margin-right: 8px !important;
    }
}

.blueText {
    background-color: $blue-2;
}
//.booking-request-div{
//    display: none !important;
//}

.test-1234 {
    display: none;
}

.custom-div-7 {
    top: 60px;
    z-index: 9999;
}
.custom-div-8 {
    position: unset;
    background: #fff;
}

.custom-div-9 {
    margin-bottom: 10px;
    padding-top: 0;
}

.custom-div-6 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    padding: 6px;
}

.red-border {
    border: solid $red-2 0.25rem !important;
}

.grey-pointed {
    // margin: 2px auto;
    background: $grey-0;
    border-radius: 8px;
    // width: 34px;
}
@media (max-width: $mobile-width) {
    .c-do-desktop-only {
        display: none !important;
    }
}